import React from "react"
import Footer from "../../../components/Footer/Footer";
import SupportLeftNav from "../../../components/TwoColumnLeftNav/SupportLeftNav";
import LocalizedLink from "../../../components/localizedLink";
import ContactForm from "../../../components/ContactForm/ContactForm";

class MailJa extends React.Component {
    constructor() {
        super();
        this.state = {
            showForm: false,
            showButton: true,
        }
    }

    agreeBtnOperation() {
        this.setState({
            showForm: true,
            showButton: false
        })
    }

    render() {

        return (
            <>
                <div>
                    <h2 className={'large bold'}>メールでのお問い合わせ</h2>
                    <div className="content-container">
                        <div className="content">
                            <p>製品や修理のお問い合わせを承っております。お問い合わせいただく際は、下記の内容をよくお読みになり、ご理解・ご同意の上お問い合わせください。</p><br/>
                            <p>【個人情報の使用目的について】</p>
                            <p>メールでのお問い合わせについて、下記の使用目的のためにお客様の個人情報を収集させていただいております。</p>
                            <ul>
                                <li> ・ お客様へのメール、お電話、お手紙などでのご連絡</li>
                                <li> ・ お問い合わせに関して、関係するグループ会社からのご連絡</li>
                                <li> ・ 製品、サービス内容向上のためのお問い合わせ内容の利用</li>
                            </ul>
                            <p> ※プライバシーポリシーは<LocalizedLink to="/company/privacy" className="direct-link">こちら</LocalizedLink>のページよりご確認いただけます。</p><br/>
                            <p>【注意事項】</p>
                            <ul>
                                <li> ・ お問い合わせ内容によっては、回答をお電話でさせていただく場合や、回答を差し上げるのにお時間をいただくこともございます。お急ぎの場合は電話相談窓口をご利用ください。</li>
                                <li> ・ 弊社からお返事させていただくことが適当でない場合には、お返事を差し上げられない場合もございます。あらかじめご了承ください。</li>
                                <li> ・ 弊社からのお返事のメールは、お客様個人宛てにお送りするものです。一部または全部を転載、二次利用することはご遠慮ください。</li>
                            </ul>
                        </div>
                    </div>
                    {
                        this.state.showButton?
                            <div>
                                <div className="agreement-pc">
                                    <LocalizedLink to="/support">
                                        <input className="disagree-btn" type="button" value="同意しません" name="disagree" />
                                    </LocalizedLink>
                                    <input className="agree-btn" type="button" value="上記内容に同意し、入力フォームに進む" onClick={()=>this.agreeBtnOperation()} name="agree" />
                                </div>
                                <div className="agreement-sp">
                                    <input className="agree-btn" type="button" value="上記内容に同意し、入力フォームに進む" onClick={()=>this.agreeBtnOperation()} name="agree" />
                                    <LocalizedLink to="/support">
                                        <input className="disagree-btn" type="button" value="同意しません" name="disagree" />
                                    </LocalizedLink>
                                </div>
                            </div>
                            :null
                    }
                    {
                        this.state.showForm?
                            <ContactForm/>
                            :null
                    }
                </div>
            </>
        )
    }
}

class MailEn extends React.Component {
    constructor() {
        super();
        this.state = {
            showForm: false,
            showButton: true,
        }
    }

    agreeBtnOperation() {
        this.setState({
            showForm: true,
            showButton: false
        })
    }

    render() {

        return (
            <>
               <div>
                   <h2 className={'large bold'}>Contact us via email</h2>
                   <div className="content-container">
                       <div className="content">
                           <p>We, Sato Keiryoki Mfg. Co., Ltd., welcome any inquiries about our products and services via
                               the following email inquiry form. </p>
                           <p>Please read the instructions below carefully before contacting us.</p><br/>
                           <p>【Purpose of use of personal information】</p>
                           <p>When you use the email inquiry form, we collect your personal information for the following
                               purposes:</p>
                           <ul>
                               <li> ・ to respond to you from us</li>
                               <li> ・ to have our dealers respond to you regarding your inquiry</li>
                               <li> ・ to use the information to improve our products and services</li>
                           </ul>
                           <p> ※ Click here to read our <LocalizedLink to="/company/privacy" className="direct-link">Privacy
                               Policy</LocalizedLink></p><br/>
                           <p>【Notice】</p>
                           <ul>
                               <li> ・ We may take time to respond to you depending on the content of the inquiry.</li>
                               <li> ・ We may not be able to respond to you in case if it is not appropriate to reply from
                                   us.
                               </li>
                               <li> ・ Our response by email is confidential and intended solely for the individuals to whom
                                   they are addressed. Reproducing and secondary use of any part of our messages is
                                   prohibited.
                               </li>
                           </ul>
                       </div>
                   </div>
                   {
                       this.state.showButton?
                           <div>
                               <div className="agreement-pc">
                                   <LocalizedLink to="/support/mail">
                                       <input className="disagree-btn" type="button" value="I don’t agree" name="disagree" />
                                   </LocalizedLink>
                                   <input className="agree-btn" type="button" value="I agree and proceed to the inquiry form" onClick={()=>this.agreeBtnOperation()} name="agree" />
                               </div>
                               <div className="agreement-sp">
                                   <input className="agree-btn" type="button" value="I agree and proceed to the inquiry form" onClick={()=>this.agreeBtnOperation()} name="agree" />
                                   <LocalizedLink to="/support/mail">
                                       <input className="disagree-btn" type="button" value="I don’t agree" name="disagree" />
                                   </LocalizedLink>
                               </div>
                           </div>
                           :null
                   }
                   {
                       this.state.showForm?
                           <ContactForm/>
                           :null
                   }
               </div>
            </>
        )
    }
}

const Mail = () => {
    const pathName = typeof window !== 'undefined' ? window.location.pathname : '';

    return (
        <>
            <article className="mail-info two-column-left-nav">
                <SupportLeftNav/>
                <section className="main-content">
                    {
                        (pathName.indexOf('/en') !== -1) ?
                            <MailEn/> :
                            <MailJa/>
                    }
                </section>
            </article>
            <Footer/>
        </>
    )
}
export default Mail